<template>

  <div class="container-sm section-default">
    <div class="row">
      <div class="col-6 d-flex align-items-center">
        <section class="py-5 text-left container">
          <h1 class="heading1 heading-site-21 text-start">Entre <span class="green-text">pessoas</span> & </h1>
          <h1 class="heading1 heading-site-21 text-start"><span class="green-text">Grandes</span> ideias</h1>
          <p class="sub-title text-start"><strong>Headhunter</strong> dos melhores Devs.
            <br>Criadores de <strong>Ideias tecnológicas</strong>.
          </p>
          <div class="d-grid gap-2 d-md-flex justify-content-md-start ">
            <router-link to="/para-empresas" class="home-button btn-primary-site-sfit-21">Aumente o seu time</router-link> 
            <router-link to="/cadastro-profissionais" class="home-button btn-primary-site-sfit-21">Criar perfil grátis</router-link>
          </div>
        </section>
      </div>
      
      <div class="col-6">
            <img src="/img/imagemhome2.png" loading="lazy" width="552" alt="" class="responsive-hide">
      </div>
    </div>
    <div class="row section-default">
      <section class="py-5 text-left container">
        <h1 class="heading1 heading-site-21 text-start">Somos <span class="green-text">tech</span></h1>
      </section>
      


<div class="container">
    <div class="row">
        <div class="col-md-3 mb-md-0 mb-2">
            <div class="card d-flex flex-column align-items-center justify-content-center">
                <div class="inner-content d-flex flex-column align-items-center justify-content-center">
                    <div class="img-container rounded-circle"> <img src="/img/1-Azul.png" alt="" class="rounded-circle"> </div>
                    <div class="h4">Tech Hunter</div>
                    <p class="designation text-muted">Recrutamos os melhores profissionais para sua empresa.<br>&nbsp;</p>
                </div>
            </div>
        </div>
        <div class="col-md-3 mb-md-0 mb-2">
            <div class="card d-flex flex-column align-items-center justify-content-center">
                <div class="inner-content d-flex flex-column align-items-center justify-content-center">
                    <div class="img-container rounded-circle"> <img src="/img/2-Azul.png" alt="" class="rounded-circle"> </div>
                    <div class="h4">Alocações</div>
                    <p class="designation text-muted">Nosso time, seu time!<br> Ganhe escala e eficiência em seus projetos.</p>
                </div>
            </div>
        </div>
        <div class="col-md-3 mb-md-0 mb-2">
            <div class="card d-flex flex-column align-items-center justify-content-center">
                <div class="inner-content d-flex flex-column align-items-center justify-content-center">
                    <div class="img-container rounded-circle"> <img src="/img/4-Azul.png" alt="" class="rounded-circle"> </div>
                    <div class="h4">Fábrica de Software</div>
                    <p class="designation text-muted">Soluções completas com a sua cara e a nossa expertise.<br>&nbsp;</p>
                </div>
            </div>
        </div>
        <div class="col-md-3 mb-md-0 mb-2">
            <div class="card d-flex flex-column align-items-center justify-content-center">
                <div class="inner-content d-flex flex-column align-items-center justify-content-center">
                    <div class="img-container rounded-circle"> <img src="/img/3-Azul.png" alt="" class="rounded-circle"> </div>
                    <div class="h4">SFiT Lab</div>
                    <p class="designation text-muted">Hub de inovação data-driven, com foco em transformar números em insights.</p>
                </div>
            </div>
        </div>        
    </div>
</div>







    </div>  
  </div>
  
</template>

<script>

</script>

<style scoped>

.heading1 {
    margin-bottom: 10px;
    font-family: Antenna,sans-serif;
    font-size: 58px;
    line-height: 60px;
    font-weight: 400;
}
.green-text {
  color: #00da80;
}

.sub-title {
    font-size: 22px;
    margin: 10px 0px 10px;
}

.container .col-md-4 {
    display: flex;
    align-items: center;
    justify-content: center
}

/* .container .card::before {
    content: "";
    position: absolute;
    top: -50px;
    height: 0;
    width: 150%;
    background-color: #5F78EA;
    z-index: -1;
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
    transition: all 0.5s ease-in-out
} */

.container .card {
    width: 280px;
    height: 320px;
    overflow: hidden;
    z-index: 3;
    transition: all 0.3s ease-in-out;
    border: none;
    /* box-shadow: 5px 5px 10px #1f1f1fa8, -5px -5px 10px #1f1f1fa8 */
    box-shadow: 1px 1px 8px 0 rgba(39,58,80,.2);
}

.container .card:hover::before {
    height: 150px
}

.container .card .img-container {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
    transition: all 0.3s ease-in-out
}

.container .card:hover .img-container {
    border: 10px solid #5F78EA
}

.container .card .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1);
    transition: all 0.3s ease-in-out
}

.container .card:hover .img-container img {
    box-shadow: 0 0 0 14px #f7f5ec;
    transform: scale(0.9)
}

.container .card .h3 {
    margin-bottom: 5px !important;
    font-weight: 600;
    pointer-events: none
}

.container .card .designation {
    font-size: 0.85rem;
    letter-spacing: 2px;
    pointer-events: none
}

.container .card ul {
    background-color: #5F78EA;
    transition: all 0.5s ease-in-out;
    transform: translateY(100px)
}

.container .card:hover ul {
    transform: translateY(40px)
}

.container .card ul li {
    padding: 5px 8px;
    transition: all 0.4s
}

.container .card ul li a {
    color: #FFF
}

.container .card ul li:hover {
    background: #fff
}

.container .card ul li:hover a {
    color: #5F78EA
}

</style>