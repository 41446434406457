import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/para-empresas',
    name: 'ParaEmpresas',
    component: () => import(/* webpackChunkName: "para-empresas" */ '../views/ParaEmpresas.vue')
  },
  {
    path: '/para-profissionais',
    name: 'Profissionais',
    component: () => import(/* webpackChunkName: "para-profissionais" */ '../views/Profissionais.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/cadastro-profissionais',
    name: 'CadastroProfissionais',
    component: () => import(/* webpackChunkName: "cadastro-profissionais" */ '../views/CadastroProfissionais.vue')
  },
  {
    path: '/cadastro-empresas',
    name: 'CadastroEmpresas',
    component: () => import(/* webpackChunkName: "cadastro-empresas" */ '../views/CadastroEmpresas.vue')
  },  
  {
    path: '/como-funciona',
    name: 'ComoFunciona',
    component: () => import(/* webpackChunkName: "como-funciona" */ '../views/ComoFunciona.vue')
  },
  {
    path: '/beneficios',
    name: 'Beneficios',
    component: () => import(/* webpackChunkName: "beneficios" */ '../views/Beneficios.vue')
  },
  {
    path: '/termos',
    name: 'termos',
    component: () => import(/* webpackChunkName: "termos" */ '../views/Termos.vue')
  },
  {
    path: '/politica-de-privacidade',
    name: 'Politica',
    component: () => import(/* webpackChunkName: "politica-de-privacidade" */ '../views/Politica.vue')
  },
  {
    path: '/fale-conosco',
    name: 'Fale',
    component: () => import(/* webpackChunkName: "fale-conosco" */ '../views/Fale.vue')
  },
  {
    path: '/esqueceu-senha',
    name: 'LembrarSenha',
    component: () => import(/* webpackChunkName: "esqueceu-senha" */ '../views/LembrarSenha.vue')
  },
  {
    path: '/sobre-voce',
    name: 'Passo1',
    component: () => import(/* webpackChunkName: "sobre-voce" */ '../views/Passo1.vue')
  },
  {
    path: '/sua-carreira',
    name: 'Passo2',
    component: () => import(/* webpackChunkName: "sua-carreira" */ '../views/Passo2.vue')
  },
  {
    path: '/teste',
    name: 'Teste',
    component: () => import(/* webpackChunkName: "teste" */ '../views/Teste.vue')
  }        
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
