<template>
  <Navbar :logo="logo_src" :alt="app_name" />
  <router-view/>
  <Footer/>
</template>

<script>
  import Navbar from './components/NavBar.vue'
  import Footer from './components/Footer.vue'

  export default {
    components: {
      Navbar, 
      Footer
    },
    data() {
      return {
        logo_src: "/img/x1.png",
        app_name: "SFIT - HeadHunter dos melhores Devs"
      }
    }
  }
</script>


<style>
  #app {
    font-family: Antenna, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #273a50;
  }
  @font-face {
    font-family: 'Antenna';
    src: url('https://assets.website-files.com/5bce158d39ce88af8aa305f6/5bce17cfe50f242018c2c1af_Antenna-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Antenna';
    src: url('https://assets.website-files.com/5bce158d39ce88af8aa305f6/5bce17cf39ce88534ca31672_Antenna-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Antenna';
    src: url('https://assets.website-files.com/5bce158d39ce88af8aa305f6/5bce17cfe50f241aa5c2c1b0_Antenna-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Antenna';
    src: url('https://assets.website-files.com/5bce158d39ce88af8aa305f6/5bce17cfe34e5ae36e81f4b1_Antenna-ExtraLight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Antenna';
    src: url('https://assets.website-files.com/5bce158d39ce88af8aa305f6/5bce17cf0508ef45c47d0c62_Antenna-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Antenna';
    src: url('https://assets.website-files.com/5bce158d39ce88af8aa305f6/5bce17cfd44cf40cbc722276_Antenna-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Antenna';
    src: url('https://assets.website-files.com/5bce158d39ce88af8aa305f6/5bce17cf0508ef61e17d0c63_Antenna-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Antenna';
    src: url('https://assets.website-files.com/5bce158d39ce88af8aa305f6/5bce17cf79f04455c6ca6623_Antenna-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Antenna';
    src: url('https://assets.website-files.com/5bce158d39ce88af8aa305f6/5bce17cf39ce88e76ba31674_Antenna-RegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Antenna';
    src: url('https://assets.website-files.com/5bce158d39ce88af8aa305f6/5bce17cfe34e5a297a81f4b2_Antenna-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
    font-display: auto;
  }

  .section-default {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .green-text {
    color: #00da80;
  }


  .home-button {
    position: relative;
    display: inline-block;
    width: auto;
    min-height: 40px;
    min-width: 150px;
    margin: 10px 10px 0px 0px;
    padding: 15px 30px;
    clear: left;
    border-radius: 4px;
    background-color: #5F78EA;
    font-family: Antenna, sans-serif;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    min-width: 258px;
  }

  .home-button:hover {
    background-color: #4D5FAF;
    color: #fff;
  }

  .form-button {
    position: relative;
    display: inline-block;
    width: auto;
    min-height: 40px;
    min-width: 150px;
    padding: 15px 30px;
    clear: left;
    border-radius: 4px;
    background-color: #5F78EA;
    font-family: Antenna, sans-serif;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    min-width: 258px;
  }  

  .form-button:hover {
    background-color: #4D5FAF;
    color: #fff;
  }  

  a {
      color: #3256a1;
      text-decoration: none;
  }

  .form-control::placeholder{color:rgba(176, 196, 218, 1); }

  .form-login_options a{
    color: #3256a1;
    display: inline-block;
    font: normal normal 700 14px/20px Open Sans;
    font-weight: 700;
    text-decoration: none;
  }
  .form-login_options a:hover{
    box-shadow:inset 0 0 0 100px rgba(176, 196, 218, 1, 0.15);
    color: #002e72;
  }

/* 
#273a50
#5F78EA
#3ABDDE
#00FF85 */



</style>

