<template>
    <div class="footer bg-light">
        <div class="container-sm">
            <div class="row justify-content-md-center">
                <div class="nav col-2 footer-menu">
                    <h6 class="footer-menu-title">Para sua carreira</h6>
                    <ul class="nav flex-column">
                        <li class="nav lista-footer"><router-link to="/cadastro-profissionais">Faça seu cadastro grátis</router-link></li>
                        <li class="nav lista-footer"><router-link to="/para-profissionais">Encontre seu novo emprego</router-link></li>
                        <li class="nav lista-footer"><router-link to="/como-funciona">Como funciona</router-link></li>
                    </ul>                             
                </div>
                <div class="nav col-2 footer-menu">
                    <h6 class="footer-menu-title">Para empresas</h6>
                    <ul class="nav flex-column">
                        <li class="nav lista-footer"><router-link to="/para-empresas">Recrute talentos</router-link></li>
                        <li class="nav lista-footer"><router-link to="/beneficios">Benefícios</router-link></li>
                        <li class="nav lista-footer"><router-link to="/cadastro-empresas">Cadastre sua empresa</router-link></li>
                    </ul>                                                                
                </div>
                <div class="nav col-2 footer-menu">
                    <h6 class="footer-menu-title">Conteúdos</h6>
                    <ul class="nav flex-column">
                        <li class="nav lista-footer"><router-link to="/#">Blog</router-link></li>
                        <li class="nav lista-footer"><router-link to="/#">Cases</router-link></li>
                        <li class="nav lista-footer"><router-link to="/#">Projetos&nbsp; Tech&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</router-link></li>
                    </ul> 
                </div>               
                <div class="nav col-2 footer-menu">
                    <h6 class="footer-menu-title">Sobre a SFiT</h6>
                    <ul class="nav flex-column">
                        <li class="nav lista-footer"><router-link to="/fale-conosco">Fale conosco</router-link></li>
                        <li class="nav lista-footer"><router-link to="#">Quem somos</router-link></li>
                        <li class="nav lista-footer"><router-link to="/termos">Termos de uso</router-link></li>
                        <li class="nav lista-footer"><router-link to="/politica-de-privacidade">Políticas de Privacidade</router-link></li>
                    </ul>                     
                </div>
                <div class="col-2">
                    <div class="d-flex justify-content-end">
                        <a href="https://www.linkedin.com/company/sfit-software-factory-it/" target="_blank" class="social-links">
                            <img src="https://assets-global.website-files.com/5bce158d39ce88af8aa305f6/612fbbf12896753210cf52e8_linkedin-icon.svg" alt="Linkedin" width="40" class="image-6">
                        </a>
                        <a href="https://www.instagram.com/sfit.io/" target="_blank" class="social-links">
                            <img src="https://assets-global.website-files.com/5bce158d39ce88af8aa305f6/612fbc07f0edc4310d3d1efe_instagram-icon.svg" alt="Instagram" width="40" class="image-8">
                        </a>
                        <a href="https://twitter.com/sfitsfit" target="_blank" class="social-links">
                            <img src="https://assets-global.website-files.com/5bce158d39ce88af8aa305f6/612fbc22b259ec20fdf91cee_twitter-icon.svg" alt="Instagram" width="40" class="image-7">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer {
    padding-top: 60px;
    background-color: rgba(39,58,80,.05);
    padding-bottom: 50px;
}
.footer-menu {
    margin-top: 16px !important;
}
.footer-menu-title {
    margin-top: 0px;
    font-family: Antenna, sans-serif;
    color: #273a50;
    font-size: 14px !important;
    font-weight: 700;
    text-transform: none;
}
.social-links {
    margin-right: 10px;
    align-self: center;
    transition: transform 200ms ease;
}
.lista-footer {
    margin-bottom: 8px;
    font-family: Antenna,sans-serif;
    color: #fff;
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
    list-style-type: none;
    white-space: nowrap;
    padding: 0 !important;
}
    a {
        color: #273a50;
        text-decoration: none;
        font-family: 'Antenna';
        font-size: 12px;
        font-weight: 300;
        transition: .5s;
    }
    
    a:hover {
       color: #72869c; 
    }
</style>