<template> 
    <nav class="navbar navbar-expand-lg navbar-sfit">
        <div class="container-sm">
            <router-link  class="brand w-nav-brand w--current" to="/"> 
                    <img class="image-2 logo-img" :src="logo" :alt="alt" >  
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-link">
                        <router-link to="/para-profissionais">Para Devs</router-link>
                    </li>
                    <li class="nav-link">
                        <router-link to="/para-empresas">Para Empresas</router-link> 
                    </li>
                    <li class="nav-item">
                        <router-link class="button-login" to="/login">Login</router-link> 
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>    
<script>
export default {
    name: "Navbar",
    props: ["logo", "alt"]
}
</script>

<style scoped>
    .navbar {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .navbar-sfit {
        position: sticky;
        z-index: 50;
        top: 0;
        background-color: #fff;
        box-shadow: 1px 1px 8px 0 rgba(39,58,80,.2);
    }

    .nav-item {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: -8px;
        /* padding: 16px 14px; */
        border: 0 solid #f8f9f9;
        font-family: Antenna,sans-serif;
        color: #273a50;
        font-size: 13px;
        font-weight: 300;
        cursor: pointer;
        margin: 6px;
    }

    .nav-link {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: -8px;
        padding: 16px 20px;
        border: 0 solid #f8f9f9;
        font-family: Antenna,sans-serif;
        color: #273a50;
        font-size: 13px;
        font-weight: 300;
        cursor: pointer;
        margin: 12px;
    }

    .nav-link:hover {
        background-color: #f5f7fb;
        color: #72869c;
    }

    .logo-img {
        width: 208px;
        height: 55px;
    }

    a {
        color: #273a50;
        text-decoration: none;
        font-family: 'Antenna';
        font-size: 13px;
        font-weight: 300;
        transition: .5s;
    }
    
    a:hover {
       color: #72869c; 
    } 

    .navbar-menu {
        position: relative;
        float: right;
    }

    .button-login {
        display: inline-block;
        width: auto;
        margin: 8px 0;
        padding: 12px 20px;
        clear: left;
        border: 1px solid #273a50;
        border-radius: 4px;
        background-color: transparent;
        font-family: Antenna,sans-serif;
        color: #273a50;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }

    .button-login:hover {
        border-style: solid;
        background-color: #5F78EA;
        color: #fff !important;
}
    

</style>
