
import "bootstrap/dist/css/bootstrap.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueAnalytics from 'vue-analytics'

createApp(App).use(router, VueAnalytics, {
    id: '307706230'
}).mount('#app')

import "bootstrap/dist/js/bootstrap.js"
